import React from "react";
import { graphql, Link } from "gatsby";
import PropTypes from "prop-types";
import Layout from "../components/layout";
import { sort } from "../components/helpers/sort";
import "../pages/tournament.css";
import Img from "gatsby-image";

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const tournament = data.tournament.edges[0].node;
  const results = sort("place", "ASC", tournament.results);

  return (
    <Layout>
      <div className='body' style={{marginTop: 50,}}>
        <Link to='/tournaments/' className="back">Back</Link>
        <div className='tournament-page__info'>
          <h2>{tournament.name}</h2>
          <p>Game: {tournament.game}</p>
          <p>{`${tournament.date ? "Date: "+tournament.date : ""}`}</p>
          <p>Tournament info: </p>
        </div>
        <div className='tournament-page__link tournament-page__link--facebook'>{
          tournament.url && 
            <div>
              <a href={tournament.url} target='_blank' rel='noopener noreferrer' style={{
                background: `url(${data.facebook.childImageSharp.fixed.src}) no-repeat left center`,
              }}>Event</a>
            </div>
        }
        </div>
        <div className='tournament-page__link tournament-page__link--challonge'>{
          tournament.url2 && 
            <div>
              <a href={tournament.url2} target='_blank' rel='noopener noreferrer' style={{
                background: `url(${data.challonge.childImageSharp.fixed.src}) no-repeat left center`,
              }}>Bracket</a>
            </div>
        }
        </div>
        <div className='tournament-page__link tournament-page__link--youtube'>{
          tournament.url3 && 
            <div>
              <a href={tournament.url3} target='_blank' rel='noopener noreferrer' style={{
                background: `url(${data.youtube.childImageSharp.fixed.src}) no-repeat left center`,
              }}>Video</a>
            </div>
        }
        </div>
        <div className='tournament-page__link tournament-page__link--smashgg'>{
          tournament.url4 &&
          <div>
            <a href={tournament.url4} target='_blank' rel='noopener noreferrer' style={{
              background: `url(${data.smashgg.childImageSharp.fixed.src}) no-repeat left center`,
            }}>Bracket</a>
          </div>
        }
        </div>
        <div style={{paddingTop: 50,}}>
          <h3 className="tournament-page__result-title">Tournament results:
            <span className="shade">&nbsp;</span>
          </h3>
          <div className="tournament-podium">
            <Img fluid={data.podium.childImageSharp.fluid}/>
            {
              results.slice(0, 1).map((el, i) =>{
                return (
                    <div className="tournament-place tournament-place--first" key={i}>
                      <div>{`${el.team ? el.team+" | " : ""}`}{el.name}</div>
                    </div>
                );
              })
            }
            {
              results.slice(1, 2).map((el, i) =>{
                return (
                    <div className="tournament-place tournament-place--second" key={i}>
                      <div>{`${el.team ? el.team+" | " : ""}`}{el.name}</div>
                    </div>
                );
              })
            }
            {
              results.slice(2, 3).map((el, i) =>{
                return (
                    <div className="tournament-place tournament-place--third" key={i}>
                      <div>{`${el.team ? el.team+" | " : ""}`}{el.name}</div>
                    </div>
                );
              })
            }
          </div>
          {
            results.slice(3,).map(( el, i ) => {
              return (
                <div className="tournament-place--rest" key={i}>
                  <div>{el.place}</div>
                  <div>{`${el.team ? el.team+" | " : ""}`}{el.name}</div>
                </div>
              );
            })
          }
        </div>
      </div>
    </Layout>
  );
}

Template.propTypes = {
  data: PropTypes.object
};

export const pageQuery = graphql`
  query($tournament: Int) {
    tournament:allTournamentsJson(filter: {index: {eq: $tournament}}) {
      edges {
        node {
          index
          name
          game
          date
          url
          url2
          url3
          url4
          results {
            name
            place
            points
            team
          }
        }
      }
    }
    facebook:file(relativePath: {eq: "facebook.png"}) {
      childImageSharp {
        fixed(width: 64, height: 64) {
          src
        }
      }
    }
    challonge:file(relativePath: {eq: "challonge.png"}) {
      childImageSharp {
        fixed(width: 64, height: 64) {
          src
        }
      }
    }
    youtube:file(relativePath: {eq: "youtube.png"}) {
      childImageSharp {
        fixed(width: 64, height: 64) {
          src
        }
      }
    }
    smashgg:file(relativePath: {eq: "smashgg.png"}) {
      childImageSharp {
        fixed(width: 64, height: 64) {
          src
        }
      }
    }
    podium:file(relativePath: {eq: "podium.png"}) {
      childImageSharp {
        fluid(maxWidth: 433, maxHeight:280 ) {
          src,
          aspectRatio
        }
      }
    }
  }
`;